import { yieldToMain } from "@app/utils/scheduler-yield";

export class HeaderDrawer {
  private drawer: Element | null;
  private openBtn: Element | null;
  private closeBtn: Element | null;

  public constructor(private document: Document = window.document) {
    this.drawer = this.document.getElementById("header-drawer");
    this.openBtn = this.document.getElementById("header-drawer-open");
    this.closeBtn = this.document.getElementById("header-drawer-close");
    this._initListeners();
  }

  private _initListeners() {
    this.openBtn?.addEventListener("click", this._toggleDrawer.bind(this));
    this.closeBtn?.addEventListener("click", this._toggleDrawer.bind(this));
  }

  private async _toggleDrawer() {
    await yieldToMain();
    const isOpen = this.drawer?.classList.toggle("open");

    this.document.body.classList.toggle("touch-none", isOpen);
    this.document.body.classList.toggle("overflow-hidden", isOpen);
  }
}
