import { isNotNil } from "@app/helpers/is-not-nil";
import type { Tracker } from "@app/tracking/tracker";

import { DropdownChip } from "./dropdown-chip";
import {
  PageSection,
  openDropdown,
  selectDropdownItem
} from "../../../tracking/dropdown-tracking";

const TRACKED_ID_LIST = ["currency-picker", "language-picker", "account-menu"];
const PAGE_SECTION = PageSection.Header;

export function HeaderDropdowns(
  window: Pick<Window, "document" | "tracker"> = global.window
) {
  if (!window || !window.document) {
    return;
  }
  const document = window.document;

  const all_dropdowns = [...document.getElementsByClassName("header-dropdown")];
  all_dropdowns.forEach(dropdown => {
    initHeaderDropdown(dropdown, { tracker: window.tracker });
    if (dropdown.getAttribute("id")?.includes(DropdownChip.dropdown_base_id)) {
      new DropdownChip(dropdown);
    }
  });
}

export function initHeaderDropdown(
  container: Element,
  options: {
    tracker?: Tracker;
  } = {}
) {
  const button = container.getElementsByClassName("header-dropdown-link")[0];
  const content = container.getElementsByClassName("js-header-dropdown")[0];

  if (!(button instanceof HTMLElement) || !(content instanceof HTMLElement)) {
    return;
  }

  const owner_document = container.ownerDocument;

  const dropdown_id =
    content.parentNode instanceof HTMLElement
      ? content.parentNode.getAttribute("id")
      : undefined;
  const tracking_enabled =
    isNotNil(dropdown_id) && TRACKED_ID_LIST.includes(dropdown_id);
  const tracker = tracking_enabled ? options.tracker : null;

  button.addEventListener("click", async () => {
    if (content.classList.contains("open")) {
      await closeHeaderDropdown();
    } else {
      await openHeaderDropdown();
    }
  });
  content.addEventListener("click", e => {
    const target = e.target;
    if (dropdown_id && target) {
      tracker?.asyncTrack(
        selectDropdownItem(dropdown_id, PAGE_SECTION, {
          clicked_section: "id" in target ? target.id : undefined
        })
      );
    }
  });

  async function openHeaderDropdown() {
    /*
     * This is added asynchronously as we want the click event
     * to propagate up the DOM before adding the event listener
     */
    setTimeout(() =>
      owner_document.addEventListener("click", closeIfClickOutside)
    );

    content.removeAttribute("hidden");
    setTimeout(() => content.classList.add("open"), 50);

    if (dropdown_id) {
      tracker?.asyncTrack(openDropdown(dropdown_id, PAGE_SECTION));
    }

    owner_document.addEventListener("keydown", closeIfEscapeKeyPressed);
  }

  async function closeHeaderDropdown() {
    owner_document.removeEventListener("click", closeIfClickOutside);
    owner_document.removeEventListener("keydown", closeIfEscapeKeyPressed);

    content.classList.remove("open");
    content.addEventListener(
      "transitionend",
      () => {
        content.setAttribute("hidden", "");
      },
      { once: true }
    );
  }

  async function closeIfClickOutside(e: MouseEvent) {
    // This dropdown implementation will close even when a click happens within
    // the content. If you want to keep the dropdown open, use event.stopPropagation
    // in your content elements (or event.nativeEvent.stopImmediatePropagation if it's
    // a React synthetic event)
    const is_inside = button.contains(e.target as Node | null);

    if (!is_inside) {
      await closeHeaderDropdown();
    }
  }

  async function closeIfEscapeKeyPressed(event: KeyboardEvent) {
    if (event.key === "Escape") {
      await closeHeaderDropdown();
    }
  }
}
