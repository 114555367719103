import { proxy } from "valtio";
import { useSnapshot } from "valtio/react";

interface SearchFormWidgetState {
  is_web_component: boolean;
  base_url: string;
}

export const search_form_widget_state = proxy<SearchFormWidgetState>({
  is_web_component: false,
  base_url: "https://www.busbud.com"
});

const useSearchFormWidgetState = () => {
  return useSnapshot(search_form_widget_state);
};

export const useIsSearchWebComponent = (): boolean => {
  const state = useSearchFormWidgetState();
  return state.is_web_component;
};

export const setIsSearchWebComponent = (is_web_component: boolean) => {
  search_form_widget_state.is_web_component = is_web_component;
};

export const setBaseUrl = (base_url: string): void => {
  search_form_widget_state.base_url = base_url;
};

export const getBaseUrl = (): string => {
  return search_form_widget_state.base_url;
};
