import type { AmplitudeEvent } from "@app/types/tracking/common";
import type {
  ClickedConnect,
  ClickedMenu,
  ClickedMenuLogout,
  ClickedMenuProfile,
  ClickedMenuTickets
} from "@app/types/tracking/header";

import { HeaderTrackingAction } from "./constants/tracking-action";

// NOTE : Logout event is handled in Google Tag Manager
// https://tagmanager.google.com/#/container/accounts/3438046/containers/1204309/tags/34

export function clickedMenu(): AmplitudeEvent<ClickedMenu> {
  return {
    action: HeaderTrackingAction.ClickedMenu,
    category: "account",
    label: "",
    details: {}
  };
}

export function clickedMenuTickets(): AmplitudeEvent<ClickedMenuTickets> {
  return {
    action: HeaderTrackingAction.ClickedMenuTickets,
    category: "account",
    label: "",
    details: {}
  };
}

export function clickedMenuProfile(): AmplitudeEvent<ClickedMenuProfile> {
  return {
    action: HeaderTrackingAction.ClickedMenuProfile,
    category: "account",
    label: "",
    details: {}
  };
}

export function clickedMenuLogout(): AmplitudeEvent<ClickedMenuLogout> {
  return {
    action: HeaderTrackingAction.ClickedMenuLogout,
    category: "account",
    label: "",
    details: {}
  };
}

export function clickedConnect(): AmplitudeEvent<ClickedConnect> {
  return {
    action: HeaderTrackingAction.HeaderClickedConnect,
    category: "account",
    label: "",
    details: {
      position: "menu",
      connect_type: "signin"
    }
  };
}
