import type { Tracker } from "@app/tracking/tracker";
import { yieldToMain } from "@app/utils/scheduler-yield";

import {
  clickedRouteCalendarDeparture,
  clickedRouteCalendarMore,
  clickedRouteCalendarTab
} from "../../tracking/price-calendar-tracking";

const TAB_NAV_CSS_SELECTOR = "[data-price-calendar-item]";
const TAB_PANE_CSS_SELECTOR = "[data-price-calendar-tab-pane]";
const MORE_LINK_CSS_SELECTOR = "[data-price-calendar-more-link]";
export const DEPARTURE_LINK_CSS_SELECTOR =
  "[data-price-calendar-departure-link]";

export class PriceCalendarBehaviour {
  private tracker: Tracker | null = null;
  private tab_navs: HTMLElement[] = [];
  private tab_panes: HTMLElement[] = [];

  public init(tracker: Tracker, document: Document) {
    this.tracker = tracker;

    this.tab_navs = Array.from(document.querySelectorAll(TAB_NAV_CSS_SELECTOR));
    this.tab_panes = Array.from(
      document.querySelectorAll(TAB_PANE_CSS_SELECTOR)
    );

    let content_index = 0;

    for (const [index, button] of this.tab_navs.entries()) {
      const current_context_index = content_index;
      const has_departures =
        button.getAttribute("data-price-calendar-has-departures") === "true";

      button.addEventListener("click", () =>
        this._handleTabNavClick(
          button,
          index,
          current_context_index,
          has_departures
        )
      );

      if (has_departures) {
        const tab_pane = this.tab_panes[current_context_index];
        this.setPaneListeners(tab_pane, index);
        content_index++;
      }
    }

    // Enabling tab pane listeners when tab_navs are disabled (can happen in some XPs)
    if (!this.tab_navs.length && this.tab_panes.length) {
      for (const [index, tab_pane] of this.tab_panes.entries()) {
        this.setPaneListeners(tab_pane, index);
      }
    }
  }

  private setPaneListeners(tab_pane: HTMLElement, index: number) {
    const more_button = tab_pane.querySelector(MORE_LINK_CSS_SELECTOR);
    if (more_button) {
      more_button.addEventListener("click", () =>
        this._handleMoreDeparturesClick(more_button, index)
      );
    }

    const departure_buttons = Array.prototype.slice.call(
      tab_pane.querySelectorAll(DEPARTURE_LINK_CSS_SELECTOR)
    );
    departure_buttons.forEach(button => {
      const annotation = button.getAttribute("data-annotation");
      button.addEventListener("click", () =>
        this._handleDeparturesClick(button, index, annotation)
      );
    });
  }

  private _handleTabNavClick(
    button: HTMLElement,
    index: number,
    content_index: number,
    has_price: boolean
  ) {
    const tracking_event = clickedRouteCalendarTab(has_price, index);

    if (has_price && (!this.tab_panes || !this.tab_navs)) {
      return;
    }

    yieldToMain().then(() => {
      if (has_price) {
        this.tab_panes.forEach(item => {
          item.classList.add("hidden");
        });
        this.tab_navs.forEach(item => {
          item.classList.remove("active");
        });
        this.tab_panes[content_index].classList.remove("hidden");
        this.tab_navs[index].classList.add("active");

        window.dispatchEvent(new CustomEvent("lazyload-refresh"));
        this.tracker?.asyncTrack(tracking_event);
      } else {
        this.tracker?.track(
          tracking_event,
          () => (window.location.href = button.getAttribute("data-href") || "")
        );
      }
    });
  }

  private _handleMoreDeparturesClick(button: Element, index: number) {
    yieldToMain().then(() => {
      this.tracker?.track(
        clickedRouteCalendarMore(index),
        () => (window.location.href = button.getAttribute("data-href") || "")
      );
    });
  }

  private _handleDeparturesClick(
    button: Element,
    index: number,
    annotation?: string
  ) {
    yieldToMain().then(() => {
      this.tracker?.track(
        clickedRouteCalendarDeparture(index, annotation),
        () => (window.location.href = button.getAttribute("data-href") || "")
      );
    });
  }
}
