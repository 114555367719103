import "@app/modules/core/touch-device";
import "@app/polyfills/dialog-polyfill";

import { CollapseVanilla } from "@busbud/horizon";

import { initializeButtonLinks } from "@app/components/button-link/button-link-script";
import { HeaderDrawer } from "@app/modules/core/vanilla/header-drawer";
import { HeaderDropdowns } from "@app/modules/core/vanilla/header-dropdowns";
import LandingPagesUI from "@app/modules/core/vanilla/landing-pages-ui";
import TrackVisibleElements from "@app/modules/core/vanilla/track-elements";
import TrackTranslate from "@app/modules/core/vanilla/track-translate";

import { CountdownTimer } from "./countdown-timer";

window.addEventListener("load", () => {
  LandingPagesUI.init(window);
  TrackVisibleElements.init();
  new CountdownTimer().start();
  HeaderDropdowns();
  new HeaderDrawer();
  initializeButtonLinks();
  TrackTranslate.init();
  new CollapseVanilla();
});
