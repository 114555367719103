import type { TrackingEvent } from "@app/types/tracking";

export const PageSection = {
  Header: "header",
  Footer: "footer",
  Navigation: "navigation"
} as const;

type PageSection = (typeof PageSection)[keyof typeof PageSection];

export function openDropdown(
  dropdown_id: string,
  page_section: PageSection,
  details: Record<string, any> = {}
): TrackingEvent {
  return {
    action: `open_${dropdown_id?.replace("-", "_")}`,
    details: { page_section, ...details }
  };
}

export function selectDropdownItem(
  dropdown_id: string,
  page_section: PageSection,
  details: Record<string, any> = {}
): TrackingEvent {
  return {
    action: `select_${dropdown_id.replace("-", "_")}_item`,
    details: { page_section, ...details }
  };
}
