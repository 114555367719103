import type { AmplitudeEvent } from "@app/types/tracking/common";
import type { ClickLink } from "@app/types/tracking/link";

import { TrackingAction } from "./constants/tracking-action";

export enum PageSection {
  HEADER = "header",
  FOOTER = "footer",
  QUESTIONS_BLOCK = "questions_block",
  SUSPENDED_OPERATIONS_BANNER = "suspended_operations_banner"
}

export enum TargetPage {
  HELP_CENTER = "help_center",
  FAQ = "faq",
  ZENDESK_ARTICLE = "zendesk_article"
}

export function clickLink(
  page_section: PageSection,
  target_page: TargetPage
): AmplitudeEvent<ClickLink> {
  return {
    action: TrackingAction.ClickLink,
    label: "",
    details: {
      page_section,
      target_page
    }
  };
}
